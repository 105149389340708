<template>
  <v-layout
    row
    wrap
    align-baseline>
    <v-flex
      xs4
      sm5
      class="event-calculate-params py-0">
      <info-tooltip
        :text="info"
        direction="right" />

      <span class="gray--text mb-0 body-2">{{ $t('calculateParams.' + label) }}</span>
    </v-flex>

    <v-spacer />

    <v-flex
      xs5
      sm3
      class="py-0">
      <v-number-field
        class="py-0"
        :set-value="value"
        :max="valueCopy"
        :disabled="valueCopy === 0"
        is-relative-max
        :data-cy="'discount' + label.charAt(0).toUpperCase() + label.slice(1)"
        @change="calculate" />
    </v-flex>
    <v-flex
      xs3
      sm4
      class="py-0 text-right">
      <span class="darkGray--text mb-0 subtitle-1">{{ discount }}%</span>
    </v-flex>
  </v-layout>
</template>

<script>
  import InfoTooltip from '@/components/ui/InfoTooltip'

  export default {
    components: { InfoTooltip },
    props: {
      param: {
        type: Number,
        required: true
      },
      label: {
        type: String,
        required: true
      },
      info: {
        type: String,
        required: false
      }
    },
    data: () => ({
      discount: 0,
      valueCopy: 0,
      value: 0.00
    }),
    beforeMount () {
      this.valueCopy = this.param
      this.value = this.param * 100
    },
    methods: {
      calculate ({discount, isCurrency}) {
        const result = ((discount / this.valueCopy * 10000) - 100) * -1
        const rule = parseFloat((discount * 100).toFixed(2))

        this.discount = this.valueCopy === 0 ? 0 : result
        this.$emit('change', { kind: this.label, rule, isCurrency })
      }
    }
  }
</script>

<style lang="sass">
  .event-calculate-params
    position: relative

    .info-icon
      left: -30px
      position: absolute
</style>
