<template>
  <v-container
    grid-list-lg
    class="pt-6 pb-0 event-params-discount">
    <v-form
      ref="formDiscount"
      v-model="validDiscount"
      lazy-validation>
      <div v-if="!displayMode">
        <v-layout
          v-if="firstRule"
          row
          wrap>
          <div class="flex xs6 sm4">
            <div class="flex py-0 pl-0 display-field xs12 sm12">
              <p class="grey--text mb-0 caption">
                Parcela
              </p>
              <p class="body-2 mb-0">
                à vista
              </p>
            </div>
          </div>
          <v-flex
            xs6
            sm4
            class="forced-flex event-params-discount__correction"
            data-cy="selectCorrectionIndex">
            <display-field
              v-if="displayMode"
              label="Correção Monetária"
              :value="params.calculateParams.correctionIndex" />
            <v-select
              v-else
              v-model="params.calculateParams.correctionIndex"
              value="id"
              item-text="id"
              item-value="id"
              label="Correção Monetária"
              :items="indexes"
              :rules="requiredRules"
              :disabled="isDisabled"
              :menu-props="{contentClass:'selectCorrectionIndex'}"
              @input="changeIndexes" />

            <info-tooltip
              text="É a adequação da moeda perante à inflação, dentro de um período determinado." />
          </v-flex>

          <v-flex
            xs6
            sm4
            class="forced-flex">
            <v-text-field
              v-model.number="params.calculateParams.correctionIndexDiscount"
              suffix="%"
              :rules="percentageRules"
              label="Desconto na Correção Monetária"
              :disabled="isDisabled"
              required
              data-cy="discountCorrectionIndex" />
          </v-flex>
        </v-layout>
        <v-layout
          v-else
          row
          wrap>
          <v-flex
            xs6
            sm1
            class="forced-flex">
            <v-text-field
              v-model.number="params.parcelOf"
              label=""
              prefix="De"
              readonly
              disabled />
          </v-flex>
          <v-flex
            xs6
            sm3>
            <v-text-field
              ref="focusElem"
              v-model.number="params.parcelUntil"
              type="number"
              label=""
              data-cy="parcelUntil"
              prefix="até"
              suffix="parcelas"
              :rules="minParcelsRules"
              required />
          </v-flex>
          <v-flex
            xs6
            sm4
            class="forced-flex"
            data-cy="selectCorrectionIndex">
            <display-field
              v-if="displayMode"
              label="Correção Monetária"
              :value="params.calculateParams.correctionIndex" />
            <v-select
              v-else
              v-model="params.calculateParams.correctionIndex"
              value="id"
              item-text="id"
              item-value="id"
              data-cy="selectCorrectionIndex"
              label="Correção Monetária"
              :menu-props="{contentClass:'selectCorrectionIndex'}"
              :rules="requiredRules"
              :items="indexes"
              :disabled="isDisabled"
              @input="changeIndexes" />
          </v-flex>

          <v-flex
            xs6
            sm4
            class="forced-flex">
            <v-text-field
              v-model.number="params.calculateParams.correctionIndexDiscount"
              suffix="%"
              :rules="percentageRules"
              label="Desconto na Correção Monetária"
              :disabled="isDisabled"
              required
              data-cy="discountCorrectionIndex" />
          </v-flex>
        </v-layout>
      </div>

      <v-switch
        v-if="!isDisplayMode"
        v-model="isApplyRules"
        label="Alterar parâmetros de cálculo nas parcelas selecionadas"
        data-cy="showCalculateParams"
        @change="$emit('updateSwitch', isApplyRules)" />

      <v-layout
        v-if="displayMode"
        row
        wrap>
        <edit-actions
          v-if="actionsEdit"
          :valid="valid"
          :display-mode="isDisplayMode"
          @change="changeDisplay"
          @save="save" />

        <v-flex sm12>
          <v-simple-table dense>
            <template v-slot:default>
              <tbody>
                <display-field suffix="%" :label="params.calculateParams.correctionIndex" :value="items.calculateParams.correctionIndexDiscount" />
                <display-field data-cy="show-discountFine" suffix="%" label="Multa Moratória" :value="items.calculateParams.fine" />
                <display-field data-cy="show-discountInterestRate" suffix="%" label="Juros Moratórios" :value="items.calculateParams.interestRate" />
                <display-field data-cy="show-discountRateInstallments" suffix="%" label="Juros Parcelamento" :value="items.calculateParams.rateInstallments" />
                <display-field data-cy="show-discountFees" suffix="%" label="Honorários Advocatícios" :value="items.calculateParams.fees" />
                <display-field class="show-discountPassExpenses" label="Repassar despesas" :value="displayExpenses" />
              </tbody>
            </template>
          </v-simple-table>
        </v-flex>
      </v-layout>

      <v-layout
        v-else-if="isApplyRules"
        row
        wrap
        justify-center>
        <v-flex
          xs6
          sm10>
          <v-layout
            row
            mb-3>
            <v-flex
              xs4
              sm5>
              <span class="gray--text mb-0 body-2">Parâmetro</span>
            </v-flex>
            <v-spacer />
            <v-flex
              xs4
              sm3>
              <span class="gray--text mb-0 body-2">Valor</span>
            </v-flex>
            <v-flex
              xs4
              sm4
              class="text-right">
              <span class="gray--text mb-0 body-2">Desconto Aplicado</span>
            </v-flex>
          </v-layout>

          <calculate-param
            v-for="(value, key) in discountRules"
            :key="key"
            :label="key"
            :param="value"
            :info="calculateParamInfo(key)"
            @change="updateCalculateParam" />
        </v-flex>
      </v-layout>

      <v-layout row wrap>
        <v-flex xs12 sm12>
          <display-field v-if="displayMode"
                         label="Desconto geral"
                         class="data-cy-show-discountGeneral"
                         :value="setGeneralDiscount" />

          <div v-else-if="isApplyRules">
            <v-switch v-model="checkedGeneralDiscount"
                      label="Desconto Geral" />

            <v-layout row wrap>
              <v-flex xs12 sm4>
                <v-radio-group v-if="checkedGeneralDiscount"
                               v-model="params.discount.type"
                               class="pt-0 ml-6"
                               :rules="requiredRules"
                               required
                               column>
                  <v-radio
                    label="Valor original"
                    :readonly="true"
                    class="discountOriginalValue"
                    value="originalValue" />
                  <!-- <v-radio label="Valor atualizado"
                           class="discountActualValue"
                           value="actualValue" /> -->
                </v-radio-group>
              </v-flex>

              <v-flex xs6 sm2>
                <v-text-field v-if="checkedGeneralDiscount"
                              v-model.number="params.discount.value"
                              data-cy="discountGeneralValue"
                              suffix="%"
                              label=""
                              :disabled="isDisabled"
                              :rules="requiredRules"
                              required />
              </v-flex>
            </v-layout>
          </div>
        </v-flex>
      </v-layout>

      <div :class="`text-${btnPosition} form-actions mt-6`">
        <v-btn
          v-if="btnCancelShow"
          data-cy="discountCancel"
          class="textColor--text"
          text
          @click.native="cancel">
          Cancelar
        </v-btn>
        <v-btn
          v-if="!hideSubmit"
          color="accent"
          data-cy="discountSubmit"
          :disabled="!validDiscount"
          @click="save">
          {{ submitLabel }}
        </v-btn>
      </div>
    </v-form>
  </v-container>
</template>

<script>
  import eventsParamsMixin from '@/mixins/events/events-params'
  import CalculateParam from '@/components/ui/events/CalculateParam'
  import InfoTooltip from '@/components/ui/InfoTooltip'

  export default {
    components: { CalculateParam, InfoTooltip },
    mixins: [ eventsParamsMixin ],
    props: {
      visible: {
        type: Boolean,
        default: false
      },
      hidePassExpenses: {
        type: Boolean,
        default: false
      },
      calculateParams: {
        type: Object,
        default: () => ({})
      },
      calculateParamsOpen: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        checkedGeneralDiscount: false,
        validDiscount: true,
        labelFinancialIndex: null,
        minParcelsRules: [
          (v) => !!v || 'Campo obrigatório',
          (v) => /^\d+$/g.test(v) || 'Digite um número válido',
          (v) => v >= this.params.parcelOf || `Deve ser igual ou maior que ${this.params.parcelOf}`
        ],
        isApplyRules: false,
        discountRules: {}
      }
    },
    computed: {
      firstRule () {
        return this.items.parcelOf === 1
      },
      setGeneralDiscount () {
        if (this.items.discount.type === '') {
          return 'Nenhum'
        } else if (this.items.discount.type === 'originalValue') {
          return `${this.items.discount.value}% do valor original`
        } else {
          return `${this.items.discount.value}% do valor atualizado`
        }
      },
      displayExpenses () {
        return this.params.calculateParams.passExpenses ? 'Sim' : 'Não'
      }
    },
    watch: {
      visible (val) {
        this.checkedDataWhenOpened(val)
      },
      checkedGeneralDiscount (val) {
        !val && this.resetGeneralDiscount()
      },
      items (val) {
        this.params = Object.assign({}, val)
        this.setExpenses(this.params.calculateParams.passExpenses)
      },
      calculateParamsOpen (val) {
        this.isApplyRules = val
      }
    },
    mounted () {
      const keysToDelete = ['correctionIndex', 'correctionIndexDiscount', 'passExpenses', '__typename']

      this.discountRules = Object.assign({}, this.calculateParams)
      keysToDelete.forEach(key => delete this.discountRules[key])
    },
    methods: {
      resetGeneralDiscount () {
        this.params.discount = {type: '', value: 0}
      },
      checkedDataWhenOpened (val) {
        if (val) {
          this.isDisplayMode = false
          // this.$refs.formDiscount.reset() // só pode resetar quando for um novo
          this.$nextTick(() => {
            this.params = Object.assign({}, this.items)
            this.checkedGeneralDiscount = this.params.discount.type !== ''
            this.setExpenses(this.params.calculateParams.passExpenses)
            this.changeIndexes(this.params.calculateParams.correctionIndex)

            if (this.params.parcelOf > 1) {
              this.$refs.focusElem.focus()
            }
          })
        }
      },
      save () {
        if (this.$refs.formDiscount.validate()) {
          this.$emit('save', this.params)
        }
      },
      changeIndexes (val) {
        if (val === undefined) {
          this.labelFinancialIndex = this.params.calculateParams.correctionIndex
        } else {
          this.labelFinancialIndex = val
        }
      },
      updateCalculateParam ({kind, rule, isCurrency}) {
        this.params.calculateParams[kind] = rule
      },
      calculateParamInfo(paramName) {
        const infos = {
          fees: 'É a remuneração recebida pelo advogado por um serviço prestado, seja por meio de lei, contrato ou decisão judicial.',
          fine: 'A multa moratória é aplicada para o caso de descumprimento de determinada obrigação.',
          interestRate: ' Juros Moratórios é cobrado pelo atraso do cumprimento de determinadas obrigações e é calculado a partir do atraso.',
          rateInstallments: 'É a taxa de juros variável e correspondente ao número de parcelas escolhido, que será acrescida ao valor dos créditos inadimplidos.',
        }

        return infos[paramName]
      }
    }
  }
</script>

<style lang="sass">
  .event-params-discount
    &__correction
      position: relative

      .info-icon
        position: absolute
        top: 10px
        left: -30px

</style>
