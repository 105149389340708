<template>
  <div class="parcel-rules">
    <h2 class="subtitle-1 mb-2">
      Regras de parcelamento
    </h2>

    <v-dialog
      v-model="dialog"
      scrollable
      content-class="modalRemoveRule"
      persistent
      max-width="800px">
      <v-card>
        <v-card-title class="pb-0">
          <h2 class="headline darkGray--text">
            {{ formTitle }}
          </h2>

          <v-spacer />

          <v-btn
            icon
            small
            class="mx-0 my-0"
            @click.native="close">
            <v-icon>{{ $root.icons.action.close }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container
            grid-list-md
            class="pb-0">
            <v-layout wrap>
              <event-params-discount
                :items="editedItem"
                :visible="dialog"
                :calculate-params="calculateParams"
                btn-cancel-show
                hide-pass-expenses
                :calculate-params-open="showCalculateParams"
                @save="save"
                @cancel="close"
                @updateSwitch="updateSwitch" />
            </v-layout>
          </v-container>
        </v-card-text>
        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="darkGray" text @click.native="close">Cancelar</v-btn>
          <v-btn color="accent" text @click.native="save">Salvar</v-btn>
        </v-card-actions> -->
      </v-card>
    </v-dialog>

    <div v-if="rulesDiscount.length > 0">
      <v-expansion-panels flat accordion>
        <v-expansion-panel
          v-for="(item, i) of rulesDiscount"
          :key="i">
          <v-expansion-panel-header class="d-flex" hide-actions>
            <span>{{ setParcel(item) }}</span>
            <v-spacer />
            <v-btn
              v-if="!displayMode"
              data-cy="removeRule"
              icon
              x-small
              class="mx-0"
              title="Apagar regra"
              :disabled="setDisabled(item)"
              @click="deleteItem(item)">
              <v-icon
                size="18px"
                color="error">
                {{ $root.icons.action.delete }}
              </v-icon>
            </v-btn>
            <v-btn
              v-if="!displayMode"
              icon
              x-small
              data-cy="editRule"
              title="Editar regra"
              :disabled="setDisabled(item)"
              @click="editItem(item)">
              <v-icon
                size="18px"
                color="primary">
                {{ $root.icons.action.edit }}
              </v-icon>
            </v-btn>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pa-0">
            <event-params-discount
              class="show-discount"
              :items="item"
              display-mode
              hide-submit />
          </v-expansion-panel-content>
          <v-divider />
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <v-layout
      v-if="!displayMode"
      :justify-end="showButton">
      <v-btn
        class="data-cy-show-rule mt-3 ml-6"
        color="accent"
        data-cy-show-rule
        elevation="0"
        @click.prevent="open">
        Adicionar
      </v-btn>
    </v-layout>

    <modal
      btn-primary-label="Sim, quero remover"
      btn-primary-color="error"
      :show="isShowRemoveRule"
      toolbar
      @close="closeModalRemoveRule"
      @submit="confirmRemove">
      <h2
        slot="title"
        class="title">
        Confirmação
      </h2>

      <div slot="content">
        <p class="title mt-6">
          Deseja realmente remover essa regra?
        </p>
      </div>
    </modal>
  </div>
</template>

<script>
  import EventParamsDiscount from './EventParamsDiscount'
  import Modal from '@/components/ui/Modal'

  export default {
    components: {
      EventParamsDiscount, Modal
    },
    props: {
      items: {
        type: [Object, Array],
        required: true
      },
      displayMode: {
        type: Boolean,
        default: false
      },
      calculateParams: {
        type: Object,
        default: () => ({})
      }
    },
    data: () => ({
      expanded: [],
      ruleIndex: 0,
      dialog: false,
      isShowRemoveRule: false,
      ruleToDeleted: null,
      headers: [
        { text: 'Parcelas', value: 'parcel', sortable: false, align: 'left' },
        { text: 'Ações', value: 'actions', sortable: false, align: 'right' }
      ],
      rulesDiscount: [],
      editedIndex: -1,
      lastParcel: 1,
      editedItem: {
        parcelOf: 1,
        parcelUntil: 1,
        calculateParams: {
          correctionIndexDiscount: 0,
          fine: 0,
          interestRate: 0,
          rateInstallments: 0,
          fees: 0
        },
        discount: {
          type: 'originalValue',
          value: 0
        }
      },
      defaultItem: {
        parcelOf: 1,
        parcelUntil: 1,
        calculateParams: {
          correctionIndexDiscount: 0,
          fine: 0,
          interestRate: 0,
          rateInstallments: 0,
          fees: 0
        },
        discount: {
          type: 'originalValue',
          value: 0
        }
      },
      editMode: false,
      isDisabled: false,
      // lastParcel: 0,
      showButton: false,
      showCalculateParams: false
    }),
    computed: {
      formTitle () {
        const editTitle = 'Editar regra de parcelamento'
        const addTitle = 'Adicionar regras de parcelamento'

        return this.editMode === 0 ? editTitle : addTitle
      }
    },
    watch: {
      dialog (val) {
        this.$emit('modal-opened', val)
        val || this.close()
      },
      editedIndex (val) {
        this.editMode = val
      },
      items (val) {
        // this.editedItem = Object.assign({}, val)
      },
      rulesDiscount (rules) {
        this.showButton = rules.length !== 0
        this.$emit('change', rules)
      }
    },
    created () {
      // this.initialize()
    },
    mounted () {
      this.displayMode && this.setRulesDiscount()
    },
    methods: {
      initialize () {
        // this.rulesDiscount = []
      },
      editItem (item) {
        this.editedIndex = this.rulesDiscount.indexOf(item)
        this.showCalculateParams = true
        this.dialog = true

        this.$nextTick(() => {
          this.editedItem.parcelUntil = item.parcelUntil
          this.editedItem = item
          this.editedItem.parcelOf = item.parcelOf
        })
      },
      deleteItem (item) {
        this.isShowRemoveRule = true
        this.ruleToDeleted = item
      },
      confirmRemove () {
        const index = this.rulesDiscount.indexOf(this.ruleToDeleted)
        this.actionsDeleteItems(index, this.ruleToDeleted)
        this.closeModalRemoveRule()
      },
      closeModalRemoveRule () {
        this.isShowRemoveRule = false
        this.ruleToDeleted = null
      },
      actionsDeleteItems (index, item) {
        this.rulesDiscount.splice(index, 1)
        this.lastParcel = item.parcelOf
        this.editedItem.parcelUntil = ''

        if (this.rulesDiscount.length === 0) {
          let reset = {
            parcelOf: 1,
            parcelUntil: 1,
            calculateParams: {
              correctionIndexDiscount: 0,
              fine: 0,
              interestRate: 0,
              rateInstallments: 0,
              fees: 0
            },
            discount: {
              type: 'originalValue',
              value: 0
            }
          }
          this.editedItem = this.defaultItem = Object.assign({}, reset)
        }
      },
      open () {
        this.dialog = true
        this.showCalculateParams = false

        if (this.editedIndex === -1) {
          this.editedItem = {
            parcelOf: 1,
            parcelUntil: 1,
            calculateParams: {
              correctionIndexDiscount: 0,
              fine: 0,
              interestRate: 0,
              rateInstallments: 0,
              fees: 0
            },
            discount: {
              type: 'originalValue',
              value: 0
            }
          }
          this.editedItem.parcelOf = this.lastParcel
          this.editedItem.parcelUntil = this.lastParcel

          this.editedItem.calculateParams.passExpenses = this.calculateParams.passExpenses
          this.editedItem.calculateParams.correctionIndex = this.calculateParams.correctionIndex
        }
      },
      close () {
        this.dialog = false

        setTimeout(() => {
          this.editedIndex = -1
        }, 300)
      },
      save (data) {
        // console.log('regra salva ', data)
        if (this.editedIndex > -1) {
          Object.assign(this.rulesDiscount[this.editedIndex], data)
        } else {
          this.rulesDiscount.push(data)
          this.showButton = true
        }

        if (data.parcelOf === 1) {
          this.lastParcel = 2
        } else {
          this.lastParcel = parseInt(data.parcelUntil) + 1
        }
        this.close()
      },
      setParcel (item) {
        if (item.parcelOf === 1) {
          return 'à vista'
        } else {
          return `${item.parcelOf} à ${item.parcelUntil}`
        }
      },
      setDisabled (item) {
        const index = this.rulesDiscount.indexOf(item)
        return parseInt(index) + 1 !== this.rulesDiscount.length
      },
      setExpenses (val) {
        return val ? 'Sim' : 'Não'
      },
      setEditedItem () {
        this.editedItem = this.items
        this.editedItem.calculateParams.passExpenses = this.items.calculateParams.passExpenses
        this.editedItem.calculateParams.correctionIndex = this.items.calculateParams.correctionIndex
        this.showButton = this.rulesDiscount.length > 1
      },
      setRulesDiscount () {
        this.rulesDiscount = this.items
      },
      toggleExpand(item) {
        this.expanded = this.expanded.includes(item) ? [] : [item]
      },
      updateSwitch (val) {
        this.showCalculateParams = val
      }
    }
  }
</script>

<style lang="sass">
  .parcel-rules
    margin-top: 20px
    position: relative

    // .v-tooltip
    //   width: 100%
    //   display: block
    //   position: absolute
    //   top: 0
    //   right: 0

    .table-clicked th.column
      padding: 8px

    // .btn--floating
    //   margin-top: -40px
    //   right: 0
    //   top: 40px
    //   position: absolute

    .table.table thead th
      white-space: normal

    .table-list-opened
      border-bottom: none!important
      background: $bgColor

      td
        background: $bgColor

    .v-datatable__expand-col--expanded
        background: $bgColor

    .v-datatable__expand-content
      // padding: 16px

    .v-datatable__expand-row
      cursor: auto

      i
        font-size: 18px

</style>
